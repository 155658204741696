import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import styles from './Alert.module.css';
import { AlertProps } from './data';
import { alertTypes } from './domain';

const alertVariants = cva(
  'bg-background-light text-secondary-variant-dark absolute bottom-8 right-8 inline-flex max-h-[50vh] max-w-[min(calc(100vw-4rem),24rem)] items-stretch gap-3 overflow-y-hidden rounded-md border p-6 pr-2 font-sans text-sm font-semibold shadow-lg dark:bg-gray-500 dark:text-white',
  {
    variants: {
      variant: {
        danger: 'border-danger',
        info: 'border-primary',
        warning: 'border-yellow-500',
        success: 'border-success',
        default: 'border-success',
      },
    },
    defaultVariants: {
      variant: 'success',
    },
  }
);

function Alert({
  label,
  variant,
  timer,
  onChange,
  className = '',
  zIndex,
  children,
}: AlertProps & VariantProps<typeof alertVariants>) {
  const [isVisible, setIsVisible] = useState(true);
  const [isFading, setIsFading] = useState(false);
  const Component = alertTypes[variant || 'default'] ?? alertTypes.default;

  const firstMounted = useRef(true);

  useEffect(() => {
    if (!firstMounted.current) onChange && onChange(isVisible);
    firstMounted.current = false;
  }, [isVisible, onChange]);

  useEffect(() => {
    setIsVisible(true);
    setIsFading(false);
    let t = setTimeout(() => {
      setIsFading(true);
      t = setTimeout(() => {
        setIsVisible(false);
      }, timer * 0.05);
    }, timer * 0.95);
    return () => clearTimeout(t);
  }, [label, children, variant, timer, onChange]);

  return isVisible ? (
    <div
      className={clsx(
        styles.alert,
        alertVariants({ variant }),
        isFading && styles.alert_animation,
        className
      )}
      style={{
        ['--duration-in' as 'width']: `${Math.min(
          Math.floor(timer * 0.1),
          1000
        )}ms`,
        ['--duration-out' as 'height']: `${Math.min(
          Math.floor(timer * 0.05),
          1000
        )}ms`,
        ...{ zIndex },
      }}
    >
      <button
        className={clsx(
          'flex h-9 w-9 items-center justify-center self-center',
          !(children || label) && 'mr-4'
        )}
        onClick={() => setIsVisible(false)}
      >
        <Component />
      </button>

      {(children || label) && (
        <div className="flex items-center overflow-y-auto">
          <p className="my-auto mr-4 max-w-[calc(100%-1rem)] whitespace-pre-wrap break-words text-left">
            {children || label}
          </p>
        </div>
      )}
    </div>
  ) : null;
}

Alert.displayName = 'Alert';

export { Alert };
