import { toast } from '@quick/components/components/Toaster/useToast';

import { storeSliceType } from '@/layers/core/data/types';
import { generalStoreType } from '@/layers/core/deal/repositories/generalStore';

import { IAlertSlice } from './alertsSliceT';

const alertsSlice: storeSliceType<generalStoreType, IAlertSlice> = (set) => ({
  alert: undefined,
  setAlert(label, variant, timer) {
    toast({ description: label, duration: timer, variant: variant });
  },
  resetAlert() {
    set({ alert: undefined });
  },
});

export { alertsSlice };
export type { IAlertSlice };
