import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { alertsSlice } from '@/layers/alerts/deal/repositories/alertsSlice';

import { generalStoreType } from './generalStoreT';

const theDevtools: typeof devtools =
  process.env.NODE_ENV === 'development'
    ? devtools
    : (((fn: unknown) => fn) as unknown as typeof devtools);

const useGeneralStore = create<generalStoreType>()(
  persist(
    theDevtools(
      (set, get, api): generalStoreType => ({
        ...alertsSlice(set, get, api),
      }),
      { name: 'generalStore' }
    ),
    {
      name: 'quick',
      // TODO: Permissions resilient storage
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['alert'].includes(key))
        ),
    }
  )
);

export { useGeneralStore };
export type { generalStoreType };
