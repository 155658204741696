import clsx from 'clsx';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import {
  ChangeEventHandler,
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { DarkSun, LightMoon } from './dumbs';

interface IDarkModeSwitcher {
  className?: string;
  onChange?: (bool: boolean) => void;
  style?: CSSProperties;
  storageKey?: string;
}

/**
 * Size changes depending on font size. Use text-4xl for a good sized one.
 */
const DarkModeSwitcher: FunctionComponent<IDarkModeSwitcher> = ({
  style,
  className,
  onChange,
  storageKey = 'dark_QC',
}) => {
  const atom = useMemo(() => atomWithStorage(storageKey, true), [storageKey]);
  const [dark, setDark] = useAtom(atom);

  useEffect(() => {
    let storage: string | null = null;
    try {
      storage = localStorage.getItem(storageKey);
    } catch (error) {
      console.error(error);
    }
    if (!storage && window?.matchMedia)
      setDark(window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches);
  }, []);

  useEffect(() => {
    if (dark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [dark]);

  const change: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { checked } }) => {
      setDark(checked);
      onChange?.(checked);
    },
    [setDark, onChange]
  );

  const isChecked = dark ?? true;

  return (
    <label
      className={clsx(
        `relative z-0 block h-[1em] w-[1em] rounded-full focus-within:outline focus-within:outline-2 focus-within:outline-gray-500 dark:focus-within:outline-white`,
        className
      )}
      style={style}
    >
      <input
        className="peer absolute z-10 h-full w-full cursor-pointer opacity-0"
        type="checkbox"
        checked={isChecked}
        onChange={change}
      />
      <div
        className={clsx(
          `relative box-border h-[1em] w-[1em] rounded-full outline outline-2 -outline-offset-1 outline-gray-500`,
          'duration-250 group bg-white transition-colors',
          'peer-checked:border-white peer-checked:bg-gray-700 peer-checked:outline-white'
        )}
        style={{ clipPath: 'circle(calc(50% + 1px) at 50% 50%' }}
      >
        <div
          className={clsx(
            'absolute h-[0.6em] w-[0.6em] origin-center',
            isChecked
              ? 'translate-x-[1.2em] translate-y-[0.2em] rotate-0 opacity-0'
              : 'translate-x-[0.2em] translate-y-[0.15em] rotate-[15deg] opacity-100'
          )}
          style={{
            transition:
              'opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71)',
          }}
        >
          <LightMoon className="absolute h-[0.6em] w-[0.6em] text-gray-700" />
        </div>
        <div
          className={clsx(
            isChecked &&
              `translate-x-[0.175em] translate-y-[0.175em] bg-[#595959] shadow-[inset_0px_0px_0px_0.075em] shadow-white`
          )}
        ></div>
        <div
          className={clsx(
            'absolute h-[0.6em] w-[0.6em] origin-center',
            isChecked
              ? 'translate-x-[0.2em] translate-y-[0.2em] rotate-[-15deg] opacity-100'
              : 'translate-x-[1.1em] translate-y-[0.2em] rotate-0 opacity-0'
          )}
          style={{
            transition:
              'opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71)',
          }}
        >
          <DarkSun className="absolute h-[0.6em] w-[0.6em] text-white" />
        </div>
      </div>
    </label>
  );
};

DarkModeSwitcher.displayName = 'DarkModeSwitcher';

export { DarkModeSwitcher };
export type { IDarkModeSwitcher };
